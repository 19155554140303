import React, { useState, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { graphql, navigate } from "gatsby";
import Layout from "../components/layoutnew";
import SEO from "../components/seo";
import HeroSection from "../components/aws/Hero";
import Overview from "../components/aws/overview";
import OpsioAWS from "../components/aws/opsioAws";
import WhyAws from "../components/aws/whyAws";
import Services from "../components/aws/services";
import SuccessStories from "../components/SuccessStories";
import Faq from "../components/newServices/faq";
import "../styles/platform.scss";

const AwsPage = ({ data }) => {
  const awsData = data.allStrapiCloudPlatform.edges[0].node;
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <Layout slug={awsData.slug} locale={awsData.locale}>
      <SEO
        title={awsData.metaTitle}
        description={awsData.metaDes}
        slug={awsData.slug}
        locale={awsData.locale}
      />
      <HeroSection
        data={awsData}
        locale={awsData.locale}
        isMobileView={isMobileView}
      />
      <Overview
        aws={{ data: awsData.overview.overview, logo: awsData.logo }}
        locale={awsData.locale}
        isMobileView={isMobileView}
      />

      <OpsioAWS
        data={awsData.opsioAws}
        locale={awsData.locale}
        isMobileView={isMobileView}
      />

      <WhyAws
        data={{ ...awsData.whyAws, WorkingSection: awsData.WorkingSection }}
        locale={awsData.locale}
        isMobileView={isMobileView}
      />

      <Services
        data={awsData.services}
        locale={awsData.locale}
        isMobileView={isMobileView}
      />

      <SuccessStories
        partner={true}
        locale={awsData.locale}
        isMobileView={isMobileView}
      />

      <Faq
        title={awsData.faqTitle}
        faqs={awsData.faqs}
        isMobileView={isMobileView}
      />
    </Layout>
  );
};

export default AwsPage;

export const query = graphql`
  query AwsPage($locale: String!, $slug: String!) {
    allStrapiCloudPlatform(
      filter: { locale: { eq: $locale }, slug: { eq: $slug } }
    ) {
      edges {
        node {
          locale
          slug
          metaTitle
          metaDes
          overview {
            overview {
              title
              heading
              description
              icons
            }
          }
          logo {
            url
          }
          HeroSection {
            title
            heading1
            heading2
            btn
          }
          navbar {
            strapi_json_value {
              label
              path
            }
          }
          opsioAws {
            title
            heading
            des1
            des2
            btn
          }
          whyAws {
            title
            description
            bulletpoints {
              name
              des
            }
          }
          services {
            title
            heading
            pills
          }
          WorkingSection {
            title
            heading1
            heading2
            footer {
              heading
              text
              btn
              email
            }
          }
          faqTitle
          faqs {
            question
            answer {
              data {
                answer
              }
            }
          }
        }
      }
    }
  }
`;

const VisibilityWrap = ({ slug, sectionId, children }) => {
  const [active, setActive] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  setTimeout(() => {
    setActive(true);
  }, 1200);

  useEffect(() => {
    const handler = () => {
      setActive(false);
      setTimeout(() => {
        setActive(true);
      }, 1000);
    };

    setScreenHeight(window.innerHeight);

    const upperClass = document.getElementsByClassName(
      "platform_upper_class"
    )[0];

    upperClass.addEventListener("click", handler);

    return () => {
      upperClass.removeEventListener("click", handler);
    };
  });

  return (
    <VisibilitySensor
      active={active}
      partialVisibility={sectionId !== "getting-started"}
      delayedCall={true}
      offset={
        sectionId !== "getting-started"
          ? {
              top: screenHeight > 0 ? screenHeight / 2 : 300,
              bottom: screenHeight > 0 ? screenHeight / 2 : 300,
            }
          : null
      }
      onChange={(isVisible) => {
        if (isVisible) {
          navigate(`/${slug}/#${sectionId}`);
        }
      }}
    >
      {children}
    </VisibilitySensor>
  );
};
